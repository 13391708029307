import React, { useCallback } from "react";
import IconButton from "@material-ui/core/IconButton";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import Badge from "@material-ui/core/Badge";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { cleverTapCommonEvents, cleverTapCommonWords } from "../../../../src/analytics/clevertapConstants";
import callClevertapEvent, { getPageNameForCleverTap } from "../../../../src/analytics/clevertapUtility";

const useStyles = makeStyles((theme) => ({
  iconBtnWithTitle: {
    marginRight: theme.spacing(-1),
  },
  badgeIcon: {
    "& > span": {
      fontSize: "8px",
      minWidth: "0px",
      background: theme.color?.red,
      lineHeight: "16px",
      borderRadius: "50%",
      width: "15px",
      height: "15px",
      color: "#fffff",
    },
  },
}));

/**
 * This component is used to show cart icon in the m-site header
 *
 * @param {object} root0 props from parent
 * @param {boolean} root0.withTitle whether page header have title or not
 *
 * @returns {React.ReactElement} - renders cart-icon-btn component
 */
const CartIconBtn = ({ withTitle }) => {
  const classes = useStyles();
  const iconStyle = withTitle ? classes.iconBtnWithTitle : "";

  const userInfo = useSelector((state) => state.userDetails);
  const cartQty = userInfo?.cartInfo?.cartTotalQuantity;

  const getCartDataFromLocalStorage =
    typeof window !== "undefined" && JSON.parse(window?.localStorage?.getItem("Cart"));
  const cartData = getCartDataFromLocalStorage?.data || {};
  const pricingSummary = cartData.pricingSummary || {};
  const additionalPromoAdjustment = cartData.additionalPromoAdjustment?.[0] || {};
  const items = cartData.items || [];

  const cleverTapCartCommonData = {
    page_name: cleverTapCommonWords.CART,
    page_type: cleverTapCommonWords.CART,
    cart_value: cartData.total ? Number(cartData.total) : 0,
    total_discount: pricingSummary.orderSavedAmount ?? 0,
    total_delivery_fee: Number(cartData.totalShipping) || 0,
    convenience_fee: additionalPromoAdjustment.additionalAmount ?? 0,
    total_product_count: items.length ?? 0,
    login_status: userInfo?.checkLoginInfo?.loggedin ?? false,
    is_cart_empty: items.length === 0,
    donation_amount: cartData.donationAmount ?? 0,
    coupon_discount: pricingSummary.couponDiscount ?? 0,
  };

  const pageHeaderMenuData = {
    page_name: getPageNameForCleverTap(),
    page_type: getPageNameForCleverTap(),
    menu_name: "Cart",
  };

  /**
   * Function navigates to cart preview screen when cart icon is clicked
   */
  const onIconClick = useCallback(() => {
    const additionalCartData = { ...cleverTapCartCommonData, fnp_cash_discount: undefined };
    callClevertapEvent(cleverTapCommonEvents.cartViewed, additionalCartData);

    callClevertapEvent(cleverTapCommonEvents.headerMenuClicked, pageHeaderMenuData);
    window.location.href = `${window.location.origin}/cartpreview`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <IconButton
      color="inherit"
      disableRipple
      aria-label="show cart quantity"
      className={iconStyle}
      onClick={onIconClick}
    >
      <Badge title="Added cart list" badgeContent={cartQty} className={classes.badgeIcon}>
        <ShoppingCartIcon />
      </Badge>
    </IconButton>
  );
};

CartIconBtn.propTypes = {
  withTitle: PropTypes.bool,
};

CartIconBtn.defaultProps = {
  withTitle: false,
};

export default CartIconBtn;
