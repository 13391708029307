import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import dynamic from "next/dynamic";
import { useDispatch, useSelector } from "react-redux";
import Modal from "@material-ui/core/Modal";
import {
  dateToDMY,
  FnpPageType,
  getFnpPageType,
  getProductDeliveryType,
  sanitizeDateStringToDate,
} from "../../src/utils/common";
import ModalFullscreenAnimated from "../mobile/pdp/modal-fullscreen-animated";
import autoSuggestStyles from "../mobile/pdp/style/autosuggest.module.scss";
import CHECKOUT_CONSTANTS from "../../src/action-constants/checkout-constants";
import { textConst } from "../../const/text-english";
import { getShippingDetails } from "../../src/actions/checkout-actions";
import { getValueFromKey, removeItemBasedOnKey } from "../../src/utils/search-utils";
import {
  checkIsInternationalProduct,
  isCourierDelivery,
  isCourierProduct,
  isPersonalizedProduct,
} from "../../src/utils/checkout";
import { getBlockedDates } from "../../src/actions/pdp-actions";
import { cleverTapCommonEvents } from "../../src/analytics/clevertapConstants";
import { CAKE_TYPE } from "../../src/utils/pdp-constants";
import callClevertapEvent from "../../src/analytics/clevertapUtility";

const DeliveryOnCalender = dynamic(() => import("../mobile/pdp/product-delivery/deliver-on/calender"), { ssr: false });
const SelectDeliveryTimeSlot = dynamic(
  () => import("../mobile/pdp/product-delivery/deliver-on/select-delivery-time-slot"),
  { ssr: false },
);

/**
 * This Component renders the Domestic calender and shipping selections
 *
 * @param {object} root0 it includes object from parent component
 * @param {Function} root0.onSelect to handle selection.
 * @param {Function} root0.giftItem giftItem
 * @param {Function} root0.getPrice to handle price.
 * @param {Function} root0.onClose to close component.
 * @param {object} root0.cleverTapCartCommonData to track cleverTap
 * @returns {React.ReactElement} jsx for the calender and shipping selections
 */
function CheckoutDelivery({ onSelect, giftItem, getPrice, onClose, cleverTapCartCommonData }) {
  const initialDate = null;
  const [open, setOpen] = useState(true);
  const [date, setDate] = useState(initialDate);
  const dispatch = useDispatch();
  const [calenderScreen, setCalenderScreen] = useState(true);
  const [deliverySlot, setDeliverySlotScreen] = useState(false);
  const [showMultiOrderModal, setShowMultiOrderModal] = useState(false);
  const [userSelectedShippingMethodId, setUserSelectedShippingMethodId] = useState(null);
  const [userSelectedTimeSlotId, setUserSelectedTimeSlotId] = useState(null);
  const [isSameDateSelected, setIsSameDateSelected] = useState(false);

  const priceSurgeDetails = useSelector((state) => state.checkout?.priceSurgeDetails);
  const productPriceByDate = useSelector((state) => state.productDetail?.productPriceByDate);
  const currency = useSelector((state) => state.appData?.selectedCurrency);
  const currencyCode = currency === "INR" ? "₹" : currency;
  const shippingDatesAndMethods = useSelector((state) => state.checkout?.shippingDatesAndMethods);
  const { catalogId } = useSelector((state) => state.appConfigs);
  const { selectedType } = useSelector((state) => state.pdpContext);

  const { deliveryDates = [], shippingDetails, isShippingDetailsExists, shippingEvents } = shippingDatesAndMethods;
  const { blockedDates = [] } = useSelector((state) => state.productDetail?.blockedDates);
  const finalDates = blockedDates.length
    ? deliveryDates?.filter((delivery) => blockedDates.indexOf(delivery) < 0)
    : deliveryDates;

  const isCartPreviewPage = getFnpPageType() === FnpPageType.CART_PREVIEW;
  const { nextAvailableDateDiff, todayDelivery, tomorrowDelivery } = shippingEvents || {};
  const productDeliveryType = getProductDeliveryType(giftItem?.primaryProductCategoryId);

  const cleverTapData = {
    product_name: giftItem.productName,
    pid: giftItem.productId,
    product_category: giftItem.primaryProductCategoryId,
    product_price: giftItem.price,
    is_variant_available: giftItem.isVariant,
    is_egg: selectedType.toUpperCase() === CAKE_TYPE.WITH_EGG,
    delivery_type: productDeliveryType,
    pincode: giftItem.pincode,
    area: giftItem.deliveryLocation,
    system_pincode: giftItem.pincode,
    country: catalogId,
    city: giftItem.deliveryLocation,
    is_international: giftItem.isIntlCatalog,
    delivery_date: giftItem.shippingDetails.deliveryDate.fullDeliveryDate,
    delivery_shipping: giftItem.shippingDetails.shippingMethodName,
    delivery_shipping_fee: giftItem.shippingDetails.shippingPrice,
    delivery_slot: giftItem.shippingDetails.deliveryTimeSlot,
    is_date_today: todayDelivery,
    is_date_tomorrow: tomorrowDelivery,
    next_available_date_difference: nextAvailableDateDiff,
    ...cleverTapCartCommonData,
  };

  /**
   * This function generates an array of strings of dates
   *
   * @returns {Array} an array of date strings to be passed on for serenades.
   */
  const setDeliveryDates = () => {
    const dates = getValueFromKey("selectedDates")?.split(",");
    return (dates && dates.map((dateObj) => dateToDMY(new Date(dateObj))).join()) || "";
  };

  useEffect(() => {
    removeItemBasedOnKey("selectedDates");
    removeItemBasedOnKey("selectedDate");
    const isInternational = checkIsInternationalProduct(giftItem?.mainproduct);
    const payload = {
      pincode: giftItem?.mainproduct?.pincode,
      productId: giftItem?.mainproduct?.productId,
      isInternational,
    };
    dispatch({ type: CHECKOUT_CONSTANTS.GET_SHIPPING_AND_DELIVERY_DATES, payload });
    getBlockedDates(giftItem?.mainproduct?.pincode, giftItem?.mainproduct?.productId, dispatch);
  }, [dispatch, giftItem.mainproduct, giftItem.mainproduct.pincode, giftItem.mainproduct.productId]);

  /**
   * This method is used to handle close delivery info screen
   *
   */
  const handleClose = () => {
    if (isCartPreviewPage) {
      callClevertapEvent(cleverTapCommonEvents.deliveryPopupClosed, cleverTapData);
    }
    setOpen(false);
    onClose();
  };

  /**
   * This method is used to handle back to  delivery info screen
   *
   */
  function handleBack() {
    if (deliverySlot) {
      setDeliverySlotScreen(false);
      setCalenderScreen(true);
    } else if (calenderScreen) {
      setDeliverySlotScreen(false);
      setCalenderScreen(false);
      handleClose();
    }
  }

  /**
   * This method is used to select date select
   *
   * @param {object} selectedDate date object.
   */
  async function handleDateSelect(selectedDate) {
    const userSelectedDate = sanitizeDateStringToDate(giftItem?.shippingDetails?.deliveryDate?.fullDeliveryDate);

    if (isCartPreviewPage) {
      const dateSelectionCleverTapData = {
        ...cleverTapData,
        is_same_date_selected: selectedDate.getDate() === userSelectedDate.getDate(),
        is_same_delivery_selected: false,
        is_same_slot_selected: false,
      };
      setIsSameDateSelected(selectedDate.getDate() === userSelectedDate.getDate());
      callClevertapEvent(cleverTapCommonEvents.deliveryDateSelected, dateSelectionCleverTapData);
    }
    userSelectedDate?.setHours(0, 0, 0, 0);
    selectedDate?.setHours(0, 0, 0, 0);
    if (selectedDate?.getTime() === userSelectedDate?.getTime()) {
      setUserSelectedShippingMethodId(giftItem?.shippingDetails?.shippingMethodId);
      setUserSelectedTimeSlotId(giftItem?.shippingDetails?.deliveryTimeSlotId);
    } else {
      setUserSelectedShippingMethodId(null);
      setUserSelectedTimeSlotId(null);
    }
    setDate(selectedDate);
    const { productId, pincode } = giftItem?.mainproduct;
    const requestParams = {
      productId,
      pinCode: pincode,
      deliveryDate: dateToDMY(selectedDate, "/"),
      countryGeoId: giftItem?.countryGeoId,
    };
    const isInternational = checkIsInternationalProduct(giftItem?.mainproduct);
    dispatch({ type: CHECKOUT_CONSTANTS.SHIPPING_DETAILS_FETCH_START });
    const shippingResponse = await getShippingDetails(requestParams, isInternational);
    if (shippingResponse?.data?.isShippingDetailsExists) {
      const payload = shippingResponse?.data;
      dispatch({ type: CHECKOUT_CONSTANTS.SET_SHIPPING_DETAILS, payload });
      setCalenderScreen(false);
      setDeliverySlotScreen(true);
    }
  }

  /**
   * This method is used to update the cartItem
   *
   * @param {object} selectedSlot slot object.
   */
  const updateCart = (selectedSlot) => {
    const { deliveryDate, slot, shipmentMethodId, dateRange } = selectedSlot;
    const data = {
      itemIndex: giftItem?.cartItemIndex,
      shippingMethodId: shipmentMethodId,
      shippingPrice: slot?.shippingPrice,
      timeSlotId: slot?.timeSlotId,
    };

    const isEstimatedDateEnabled =
      giftItem?.estimatedDateEnabled && giftItem?.estimatedDateEnabled?.split(",")[0] === "Y";

    if (isEstimatedDateEnabled) {
      data.estimatedDateEnabled = "N";
    }

    if (giftItem?.isMultiOrderProduct) {
      data.deliveryDatesList = dateRange;
    } else {
      data.deliveryDate = dateToDMY(new Date(deliveryDate));
    }
    onSelect(data);
  };

  /**
   * This method is used to select time slot
   *
   * @param {object} selectedSlot selected time slot object.
   */
  function handleTimeSlotSelect(selectedSlot) {
    updateCart(selectedSlot);
    setCalenderScreen(false);
    setDeliverySlotScreen(false);
    handleClose();
  }

  return (
    <div className={`${autoSuggestStyles["deliver-on"]} mt20`}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        disableScrollLock
      >
        <ModalFullscreenAnimated
          screen={textConst.checkout.shippingScreen}
          handleHeaderBack={handleBack}
          animated={!false}
          headerTitle={
            calenderScreen ? textConst.checkout.selectDeliveryDate : textConst.checkout.selectDeliveryTimeSlot
          }
        >
          {calenderScreen && deliveryDates?.length > 1 && (
            <DeliveryOnCalender
              onSelect={handleDateSelect}
              deliveryDates={finalDates}
              isCourierProduct={isCourierProduct(giftItem?.mainproduct)}
              isPersonalizedProduct={isPersonalizedProduct(giftItem?.mainproduct)}
              isCourierDelivery={isCourierDelivery(giftItem?.mainproduct)}
              productDetail={{
                product: {
                  ...giftItem?.mainproduct,
                  isMultiOrderProduct: giftItem?.isMultiOrderProduct,
                  multiOrderProductDetails: giftItem?.multiOrderProductDetails,
                },
                priceSurgeDetails,
                productPriceByDate,
                shippingDatesAndMethods,
              }}
              pinCode={giftItem?.mainproduct?.pincode}
              itemDesiredDeliveryDate={giftItem?.shippingDetails?.deliveryDate?.fullDeliveryDate}
              itemDesiredDeliveryDatesList={
                giftItem?.isMultiOrderProduct ? giftItem?.shippingDetails?.selectedDatesList?.toString() : ""
              }
              selectedVariant={() => {}}
              checkIsInternationalProduct={checkIsInternationalProduct(giftItem?.mainproduct)}
              getPrice={getPrice}
              currencyCode={currencyCode}
              showMultiOrderModal={showMultiOrderModal}
              setShowMultiOrderModal={setShowMultiOrderModal}
            />
          )}
          {deliverySlot && (
            <SelectDeliveryTimeSlot
              selectedDeliveryDate={date}
              selectedDeliveryDates={giftItem?.isMultiOrderProduct ? setDeliveryDates() : ""}
              onSelect={handleTimeSlotSelect}
              shippingDetails={shippingDetails}
              isShippingDetailsExists={isShippingDetailsExists}
              getPrice={getPrice}
              currencyCode={currencyCode}
              checkIsInternationalProduct={checkIsInternationalProduct(giftItem?.mainproduct)}
              shippingMethodId={userSelectedShippingMethodId}
              selectedTimeSlotId={userSelectedTimeSlotId}
              cleverTapData={cleverTapData}
              isSameDateSelected={isSameDateSelected}
            />
          )}
        </ModalFullscreenAnimated>
      </Modal>
    </div>
  );
}

CheckoutDelivery.propTypes = {
  onSelect: PropTypes.func.isRequired,
  getPrice: PropTypes.func.isRequired,
  giftItem: PropTypes.objectOf(PropTypes.any).isRequired,
  onClose: PropTypes.func.isRequired,
  cleverTapCartCommonData: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default CheckoutDelivery;
