export const cleverTapConfig = {
  clevertapAccountId: "RZK-94K-8Z7Z",
  clevertapRegion: "in1",
};

export const cleverTapEvents = {
  common: "Common",
  userLogin: "UserLogin",
};

export const cleverTapCommonEvents = {
  pageViewed: "Page Viewed",
  cartViewed: "Cart Viewed",
  proceedToCheckoutClicked: "Proceed To Checkout Clicked",
  addonRemoved: "Addon Removed",
  productRemoved: "Product Removed",
  addonAdded: "Addon Added",
  fnpCashApplied: "Fnp Cash Applied",
  addonContinueClicked: "Addon Continue Clicked",
  addonClosed: "Addon Closed",
  deliveryPopupViewed: "Delivery Popup Viewed",
  deliveryDateSelected: "Delivery Date Selected",
  deliveryShippingSelected: "Delivery Shipping Selected",
  deliverySlotSelected: "Delivery Slot Selected",
  deliveryPopupClosed: "Delivery Popup Closed",
  bannerClicked: "Banner Clicked",
  headerMenuClicked: "Header Clicked",
  searched: "Searched",
  bottomNavigationNavIconClicked: "Bottom Navigation Nav Icon Clicked",
  locationClicked: "Location Clicked",
  locationPopupViewed: "Location Popup Viewed",
  locationPopupClosed: "Location Popup Closed",
  locationPincodeSelected: "Location Pincode Selected",
  locationDontKnowPincodeClicked: "Location Dont Know Pincode Clicked",
  locationAreaSelected: "Location Area Selected",
  locationRemoved: "Location Removed",
  locationSaved: "Location Saved",
  locationCountrySelected: "Location Country Selected",
  locationCitySelected: "Location City Selected",
  productClicked: "Product Clicked",
  pageScrolled: "Page Scrolled",
  sortSelected: "Sort Selected",
  filterSelected: "Filter Selected",
  validationViewed: "Validation Viewed",
};

export const cleverTapCommonWords = {
  PDP: "PDP",
  CART: "CART",
  PLP: "PLP",
  CATEGORY: "CATEGORY",
  HOME: "HOME",
  area: "AREA",
  MICROSITE: "MICROSITE",
  ACCOUNT: "ACCOUNT",
  PRODUCT: "PRODUCT",
};
