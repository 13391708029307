import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import { Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import FullScreenDialog from "../../core/fnp-modal/full-screen-dialog";
import { requestPlpListingOnClient, startProductListSorting } from "../../../../src/actions/plp-actions";
import { PathPageConstants } from "../../../../src/utils/common";
import { setPopUpStatus } from "../../../../src/actions/user-actions";
import FilterBody from "./filter-body";
import { PLP } from "../../../common/constants";
import { clearAllFilter, createPLPRequestForAppliedFilter } from "../../../../src/utils/plp-helper";
import { setGA4FilterSelected } from "../../../../src/ga/gaEvents";
import callClevertapEvent from "../../../../src/analytics/clevertapUtility";
import { textConst } from "../../../../const/text-english";
import { cleverTapCommonWords } from "../../../../src/analytics/clevertapConstants";

/**
 * This functional component represents the E-commerce filter page.
 *
 * @param {object} root0 object of props
 * @param {boolean} root0.open flag to determine if the modal is open
 * @param {Function} root0.handleCloseScreen function that handles the close functionality of the filters
 * @returns {React.Fragment} return facet filters.
 */
const FacetFilters = ({ open, handleCloseScreen }) => {
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const { searchOptions } = useSelector((state) => state.productsLists);
  const router = useRouter();
  const dispatch = useDispatch();

  /**
   * Function to close confirm modal
   */
  const handleConfirmModalClose = () => {
    setOpenConfirmModal(false);
  };

  /**
   * Function to reset popup status
   */
  const resetStates = () => {
    dispatch(setPopUpStatus(false));
  };

  /**
   * This function for setting clearFilters URL and handleSelection
   *
   * @param {string} URLParams selected for delete from url
   */
  const routeForClearedFilters = (URLParams) => {
    router.push(
      router.pathname,
      `${window.location?.pathname}${URLParams.toString() && `?${URLParams.toString()}`}${window.location.hash}`,
      {
        shallow: true,
      },
    );
  };

  /**
   * This function handles the selection of filters and sort
   *
   * @param {object} searchParam url param
   */
  const handleSelection = useCallback(
    (searchParam) => {
      const searchOptionsParams = createPLPRequestForAppliedFilter(searchParam, searchOptions.params);
      const pageFilterSelectedData = {
        page_name: `${searchOptionsParams?.params.categoryId}`,
        page_type: cleverTapCommonWords.PLP,
        filter: searchOptionsParams?.params?.fq,
      };
      callClevertapEvent(textConst.cleverTap.filterSelected, pageFilterSelectedData);
      setGA4FilterSelected(searchOptionsParams?.params?.fq);
      window.scrollTo(0, 0);
      dispatch(startProductListSorting());
      dispatch(requestPlpListingOnClient({ options: searchOptionsParams, appendProducts: false }));
    },
    [dispatch, searchOptions.params],
  );

  /**
   * This function for clear all the filters for url
   */
  const clearAllFilterInUrl = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const updatedURLParams = clearAllFilter(urlParams);
    handleSelection(updatedURLParams);
    routeForClearedFilters(updatedURLParams);
    resetStates();
  };

  /**
   * Function triggers when clear all is clicked
   */
  const handleClearAll = () => {
    if (window.location.search) {
      clearAllFilterInUrl();
    }
  };

  /**
   * Function return clear all component
   *
   * @returns {React.ReactNode} clear all component
   */
  const clearAllComponent = () => {
    return () => {
      return <Typography onClick={handleClearAll}>Clear All</Typography>;
    };
  };

  return (
    <FullScreenDialog
      open={open}
      handleClose={handleCloseScreen}
      pathName={PathPageConstants.PLP_FILTER_VIEW}
      headerLabel={PLP.FILTERS}
      titleStyle={{ textAlign: "left", fontWeight: "400" }}
      rightComponent={clearAllComponent()}
    >
      <FilterBody
        openConfirmModal={openConfirmModal}
        handleClose={handleCloseScreen}
        handleConfirmModalClose={handleConfirmModalClose}
        handleSelection={handleSelection}
      />
    </FullScreenDialog>
  );
};

export default FacetFilters;

FacetFilters.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseScreen: PropTypes.func.isRequired,
};
