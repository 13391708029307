import { useEffect } from "react";

let cleverTapInstance;

/**
 * Main function to be executed first to initiate all the setup for CleverTap
 *
 * @param {string} accountId  accountId
 * @param {string}  region region
 */
function initClevertapSetup(accountId, region) {
  if (typeof window !== "undefined") {
    const cleverTap = require("clevertap-web-sdk"); // eslint-disable-line global-require
    cleverTap.init(accountId, region);
    cleverTapInstance = cleverTap;
    cleverTapInstance.notifications.push({
      titleText: "Stay Updated!",
      bodyText: "Allow notifications to receive the latest offers and updates.",
      okButtonText: "Allow",
      rejectButtonText: "No, Thanks",
      askAgainTimeInSeconds: 86400,
      okButtonColor: "#737530",
      serviceWorkerPath: "/clevertap_sw.js",
    });
  }
}

/**
 * This function returns cleverTap instance
 *
 * @returns {Function} clevertap instance
 */
function getCleverTapInstance() {
  return cleverTapInstance;
}

/**
 * CleverTap setup hook
 *
 * @param {string} accountId  accountId
 * @param {string}  region region
 */
export default function useClevertapSetup(accountId, region) {
  useEffect(() => {
    initClevertapSetup(accountId, region);
  }, [accountId, region]);
}

export { getCleverTapInstance };
